<template>
	<main id="main">
		<div class="container">

			<!-- ▼ タイトル -->
			<div class="row title-wrapper m-0">
				<div class="title-content">
					<h1 class="questionnaire"><div class="container visual">会報連載「ダイレクト マイペン」内田雄馬に書いてほしい言葉</div></h1>
				</div>
			</div>
			<!-- ▲ タイトル -->
			
			<!-- ▼ コンテンツ -->
			<div class="main-content questionnaire pt-0">
				<div class="container visual">
					<div class="container medium">
					
						<!-- ▼ 冒頭テキスト -->
						<p>会報の連載企画「ダイレクト マイペン」にて、内田雄馬に書いてほしい言葉を募集します！</p>
						<p>今回のお題は「みなさんの趣味」です！<br>
							あなたが内田雄馬に書いてほしい言葉をぜひお送りください。</p>
						<p>みなさまからのご応募お待ちしております！</p>
						<!-- ▲ 冒頭テキスト -->
						
						<!-- ▼ 注意事項 -->
						<p>【注意事項】</p>
						<ul class="asterisk mt-0 text-small">
							<li>お1人様何回でも応募が可能です。1回のご応募につき、1単語ずつご応募ください。</li>
							<li>人名・キャラクター名・商品名等の応募はご遠慮ください。</li>
							<li>言葉の表記は漢字・ひらがな・カタカナ・アルファベットいずれも可（ご応募いただいた通りに記載します）。</li>
							<li>ご応募は言葉（単語）のみとなります。<br>（例）OK：ポテトフライ&ensp;NG：熱々のポテトフライ</li>
						</ul>
						<!-- ▲ 注意事項 -->

						<!-- ▼ 応募締切 -->
						<div class="oubo">
							<p>【 募集期間 】</p>
							<p>8月22日（木）18:00～9月5日（木）23:59</p>
						</div>
						<!-- ▲ 応募締切 -->

					</div>
					
					<hr>
					
					<div class="container medium">
						<!-- ▼ アンケートフォーム -->
						<form method="post" action="https://survey.modd.com/public/webapi/surveys/7ea72317-41a1-4248-ab85-00cc81566a3c/answers/form" enctype="application/x-www-form-urlencoded">
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2 class="confirm"><span class="required-mark">※</span>内田雄馬に書いてほしい単語をご記入ください。（20 文字以内）</h2>
								<div class="answer"><p>{{data[1]}}</p></div>
							</div>
							<!-- ▲ テキストエリア -->
							
							<input type="hidden" name="i00_userid" :value="data[0]" hidden>
							<input type="hidden" name="i01_textarea01" :value="data[1]" hidden>

							<div class="pt-2 pt-md-5">
								<div class="row justify-content-center mt-5">
									<div class="col-auto">
										<a class="canClick btn-border big auto" @click="backInput">戻る</a>
									</div>
									<div class="col-auto">
										<button type="submit" style="border:none; background:unset;"><a class="canClick btn-theme auto px-5">回答する</a></button>
									</div>
								</div>
							</div>

						</form>
						<!-- ▲ アンケートフォーム -->
					</div>
				</div>
			</div>
			<!-- ▲ コンテンツ -->
		</div>
	</main>
</template>

<script>
	export default{
		data(){
			return{
				data:[],
				referrer:this.$router.referrer,
			}
		},
		created(){
			// scrollTo(0,0)
			this.chkReferrer()
		},
		methods:{
			chkReferrer(){
				if(this.referrer.path != '/fanclubtop/questionnaire/directmypen09'){
					window.location.href = '/fanclubtop'
				}else{
					this.getData()
				}
			},
			getData(){
				this.data = this.$store.getters.get_directmypen
				
			},
			backInput(){
				this.$router.push('/fanclubtop/questionnaire/directmypen09')
			},
		},
	}
</script>