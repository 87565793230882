<template>
	<main id="main">	
		<div class="container">
		
			<!-- ▼ タイトル -->
			<div class="row title-wrapper m-0">
				<div class="title-content">
					<h1 class="questionnaire"><div class="container visual">会報連載「ダイレクト マイペン」内田雄馬に書いてほしい言葉</div></h1>
				</div>
			</div>
			<!-- ▲ タイトル -->
			
			<!-- ▼ コンテンツ -->
			<div class="main-content questionnaire pt-0">
				<div class="container visual">
					<div class="container medium">
					
						<!-- ▼ 冒頭テキスト -->
						<p>会報の連載企画「ダイレクト マイペン」にて、内田雄馬に書いてほしい言葉を募集します！</p>
                        <p>今回のお題は「みなさんの趣味」です！<br>
                            あなたが内田雄馬に書いてほしい言葉をぜひお送りください。</p>
                        <p>みなさまからのご応募お待ちしております！</p>
						<!-- ▲ 冒頭テキスト -->
						
                        <!-- ▼ 注意事項 -->
                        <p>【注意事項】</p>
						<ul class="asterisk mt-0 text-small">
							<li>お1人様何回でも応募が可能です。1回のご応募につき、1単語ずつご応募ください。</li>
                            <li>人名・キャラクター名・商品名等の応募はご遠慮ください。</li>
                            <li>言葉の表記は漢字・ひらがな・カタカナ・アルファベットいずれも可（ご応募いただいた通りに記載します）。</li>
                            <li>ご応募は言葉（単語）のみとなります。<br>（例）OK：ポテトフライ&ensp;NG：熱々のポテトフライ</li>
						</ul>
						<!-- ▲ 注意事項 -->

						<!-- ▼ 応募締切 -->
						<div class="oubo">
							<p>【 募集期間 】</p>
							<p>8月22日（木）18:00～9月5日（木）23:59</p>
						</div>
						<!-- ▲ 応募締切 -->

					</div>
					
					<hr>
					
					<div class="container medium">
						<!-- ▼ アンケートフォーム -->
						<form>
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2><span class="required-mark">※</span>内田雄馬に書いてほしい単語 をご記入ください。（20 文字以内）</h2>
								<textarea ref="i01_textarea" v-model="textarea01" @blur="i01TextareaChange"></textarea>
							</div>
							<!-- ▲ テキストエリア -->

							<!-- ▼ fcid -->
							<input type="hidden" name="i00_form_id" v-model="id">
							<!-- ▲ fcid -->



							<div class="pt-2 pt-md-5">
								<p class="mt-5 alert text-center font-weight-600" ref="errorRequired">※未入力の項目があります。</p>
								<p class="mt-5 alert text-center font-weight-600" ref="errorOverlength01">※20文字以内でご入力ください。</p>
								<div class="row justify-content-center mt-5">
									<div class="col-auto">
										<a href="/fanclubtop" class="btn-border big auto">TOP</a>
									</div>
									<div class="col-auto">
										<a class="canClick btn-theme auto px-5" @click="chkForms">確認する</a>
									</div>
								</div>
							</div>

							
						</form>
						<!-- ▲ アンケートフォーム -->
					</div>
				</div>
			</div>
			<!-- ▲ コンテンツ -->
			
		</div>
	</main>
</template>

<style>
.alert{
	display: none;
}
</style>

<script>
export default{
	data(){
		return{
			host: process.env.VUE_APP_IAM,
			cart: process.env.VUE_APP_CARTURI,
			cms: process.env.VUE_APP_CLIENTAPI,
			path: this.$route.path,
			pMeta:[],
			id:'',
			textarea01:'',
			sendData:this.$store.getters.get_directmypen,
			required11:false,
			required12:false,
		}
	},
	created(){
		// scrollTo(0,0)
		this.getData()
	},
	methods:{
		async getData(){
			try{
				const response = await this.$axios.get(this.$uri+'/data_output', { withCredentials: true })
				this.pMeta = response.data.pageMeta
				let loginAuth = this.pMeta.isAuthenticated
				this.id = this.pMeta.userInfo.fc_no
				if(response.data.siteMeta.isInMaintenance){
					window.location.href="/maintenance/maintenance.html"
				}else if(!loginAuth){
					this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
					window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
				}else{
					this.textarea01 = this.sendData[1]
				}
			}catch(e){
				window.location.href="/error/504.html"
			}
		},
		i01TextareaChange(){
			//required
			let target = this.$refs.i01_textarea
			if(this.textarea01 == '' || this.textarea01.length > 20) {
				if(!target.classList.contains('error')){
					target.classList.add('error')
				}
				if(this.textarea01 == '') {
					this.required011 = true
				} else {
					this.required011 = false
				}
				if(this.textarea01.length > 20) {
					this.required012 = true
				} else {
					this.required012 = false
				}
			} else {
				if(target.classList.contains('error')){
					target.classList.remove('error')
				}
				this.textarea01 = this.textarea01.replace(/</g, '&lt;')
					.replace(/>/g, '&gt;')
					.replace(/"/g, '&quot;')
					.replace(/'/g, '&#x27')
				this.required011 = false
				this.required012 = false
			}
		},
		chkForms(){
			let alertBlockRequired = this.$refs.errorRequired
			let alertBlockOver01 = this.$refs.errorOverlength01
			this.i01TextareaChange()

			if(this.required011){
				alertBlockRequired.style.display = 'block'
			} else {
				alertBlockRequired.style.display = 'none'				
			}
			if(this.required012) {
				alertBlockOver01.style.display = 'block'
			} else {
				alertBlockOver01.style.display = 'none'				
			}
			if(!this.required011 && !this.required012){
				this.sendData[0] = this.id
				this.sendData[1] = this.textarea01

				this.$store.commit('CHANGE_DIRECTMYPEN',this.sendData)

				this.$router.push('/fanclubtop/questionnaire/directmypen09-confirm')
			}
		}
	},
}
</script>