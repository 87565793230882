import Vue from 'vue'
import Vuex from 'vuex'
import multidata from './modules/multidata'
import directmypen from './modules/directmypen'
// import questionnaire01 from './modules/questionnaire01'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    multidata,
    directmypen,
  },
})