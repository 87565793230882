//import Vue from 'vue'

const state = {
	postData:[
	'',
	'',
	]
}

const mutations = {
	'CHANGE_DIRECTMYPEN'(state, val){
		state.postData = val
	},
};

const actions = {
	set_directmypen:({commit},val) => {
		commit('CHANGE_DATA',val)
	},
};

const getters = {
	get_directmypen: state => {
		return state.postData
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
}